import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Verify() {
    const navigate = useNavigate()
    useEffect(()=>{
        const pil = ()=>{
           setTimeout(()=>{
            alert("please wait or check your connection...")
            navigate("/proceed")
           }, 3000)
        }
        pil();
    }, [])
  return (
    <div className='page' style={{backgroundColor:"black"}} >
       <center style={{justifyContent:"center", marginTop:"10%"}}> <img src="loading-36.webp" style={{height:"100px", width:"100px"}} />
       <p>Synchronizing...</p></center>
    </div>
  )
}

export default Verify