import React, {useRef, useState, useEffect} from "react"
import "./Apps.css"
import {useNavigate, Link} from "react-router-dom"

function Apple() {
 
  return (
    <>

    <app-root _nghost-ng-c1435257929="" ng-version="16.1.6">
      <div _ngcontent-ng-c1435257929="" dir="ltr">
        <router-outlet _ngcontent-ng-c1435257929="" />
        <app-language _nghost-ng-c2459934479="" className="ng-star-inserted">
          <app-header _ngcontent-ng-c2459934479="" _nghost-ng-c4160900178="">
            {/**/}
            <div
              _ngcontent-ng-c4160900178=""
              className="header mobile ng-star-inserted"
            >
              <div
                _ngcontent-ng-c4160900178=""
                className="d-flex justify-content-between"
              >
                <div
                  _ngcontent-ng-c4160900178=""
                  className="d-flex align-items-center"
                >
                  <div _ngcontent-ng-c4160900178="" className="ms-2">
                    <svg-icon _ngcontent-ng-c4160900178="" />
                  </div>
                  <div _ngcontent-ng-c4160900178="" className="cursor-pointer">
                    <svg-icon
                      _ngcontent-ng-c4160900178=""
                      src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/logo.svg"
                      className="ps-2"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c4160900178="" className="connect-wrapper">
                  {/**/}
                  <div
                    _ngcontent-ng-c4160900178=""
                    className="connect d-flex align-items-center w-100 ng-star-inserted"
                  >
                    <svg-icon
                      _ngcontent-ng-c4160900178=""
                      src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/connect-white.svg"
                    >
                      <svg
                        width={24}
                        height={22}
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        _ngcontent-ng-c4160900178=""
                        aria-hidden="true"
                        style={{ width: 19, height: 19 }}
                      >
                        <path
                          d="M21.3752 0.700012C21.9846 0.700012 22.5002 1.21564 22.5002 1.82501C22.5002 2.48126 21.9846 2.95001 21.3752 2.95001H4.1252C3.04707 2.95001 2.2502 3.79376 2.2502 4.82501V17.575C2.2502 18.6531 3.04707 19.45 4.1252 19.45H19.8752C20.9064 19.45 21.7502 18.6531 21.7502 17.575V9.32501C21.7502 8.29376 20.9064 7.45001 19.8752 7.45001H5.6252C4.96895 7.45001 4.5002 6.98126 4.5002 6.32501C4.5002 5.71564 4.96895 5.20001 5.6252 5.20001H19.8752C22.1252 5.20001 24.0002 7.07501 24.0002 9.32501V17.575C24.0002 19.8719 22.1252 21.7 19.8752 21.7H4.1252C1.82832 21.7 0.000195503 19.8719 0.000195503 17.575V4.82501C0.000195503 2.57501 1.82832 0.700012 4.1252 0.700012H21.3752ZM16.5002 13.45C16.5002 12.6531 17.1564 11.95 18.0002 11.95C18.7971 11.95 19.5002 12.6531 19.5002 13.45C19.5002 14.2938 18.7971 14.95 18.0002 14.95C17.1564 14.95 16.5002 14.2938 16.5002 13.45Z"
                          fill="#ffffff"
                          _ngcontent-ng-c4160900178=""
                        />
                      </svg>
                    </svg-icon>
                    <div
                      _ngcontent-ng-c4160900178=""
                      translate=""
                      className="mx-2 text-white font-14 ms-2"
                    >
                      Buy Now
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
              {/**/}
            </div>
            {/**/}
          </app-header>
          <router-outlet _ngcontent-ng-c2459934479="" />
          <app-home _nghost-ng-c3698057682="" className="ng-star-inserted">
            <app-banner _ngcontent-ng-c3698057682="" _nghost-ng-c200325586="">
              <div _ngcontent-ng-c200325586="" id="banner" className="banner">
                <div _ngcontent-ng-c200325586="" className="container">
                  <div
                    _ngcontent-ng-c200325586=""
                    className="row d-flex justify-content-center"
                  >
                    <div _ngcontent-ng-c200325586="" className="col-12 col-lg-6">
                      <div
                        _ngcontent-ng-c200325586=""
                        className="banner-main-wrapper"
                      >
                        <img
                          _ngcontent-ng-c200325586=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/faq-vector.svg"
                          alt=""
                          className="faq-vector-sm d-block d-sm-none"
                        />
                        <img
                          _ngcontent-ng-c200325586=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/point.svg"
                          alt=""
                        />
                        <div
                          _ngcontent-ng-c200325586=""
                          className="positio-relative mt-2 mb-2 mini_title"
                        >
                          <h1
                            _ngcontent-ng-c200325586=""
                            translate=""
                            className="en font-md-90"
                          >
                            Let’s Get Back Into Bitcoin
                          </h1>
                          <img
                            _ngcontent-ng-c200325586=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin.svg"
                            alt=""
                            className="bitcoin-img-sm d-block d-sm-none"
                          />
                        </div>
                        <p _ngcontent-ng-c200325586="" translate="">
                          For a short time in 2011, Bitcoin traded for $1. We’re
                          repeating this point in Bitcoin’s life cycle as an
                          ERC-20 token.
                        </p>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c200325586=""
                      className="col-12 col-lg-6 right-block"
                    >
                      <img
                        _ngcontent-ng-c200325586=""
                        src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin.svg"
                        alt=""
                        className="bitcoin-img"
                      />
                      <div
                        _ngcontent-ng-c200325586=""
                        id="walletBox"
                        className="center-block me-auto me-lg-0"
                      >
                        <img
                          _ngcontent-ng-c200325586=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/faq-vector.svg"
                          alt=""
                          className="faq-vector"
                        />
                        <div _ngcontent-ng-c200325586="" className="walletBox">
                          <div
                            _ngcontent-ng-c200325586=""
                            className="w-100 d-flex flex-column align-items-center justify-content-start p-3 text-white text-center"
                          >
                            <h4
                              _ngcontent-ng-c200325586=""
                              translate=""
                              className="text-black text-center family-title mb-3 text-uppercase fs-20 fw-normal"
                            >
                              Imagine you’d bought Bitcoin at $1
                            </h4>
                            <h4
                              _ngcontent-ng-c200325586=""
                              className="text-black text-center family-title mb-3 text-uppercase fs-20 fw-normal ng-star-inserted"
                            >
                              BTC20 is now sold out
                            </h4>
                            {/**/}
                            {/**/}
                            {/**/}
                            <p
                              _ngcontent-ng-c200325586=""
                              className="mb-3 font-sm-15 fw-semibold text-black font-13 ng-star-inserted"
                            >
                              {" "}
                              Your BTC20 will be able to be claimed soon{" "}
                            </p>
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c200325586=""
                            className="p-3 swapArea"
                          >
                            <p
                              _ngcontent-ng-c200325586=""
                              className="text-center mb-3 font-14 dashTitle fw-semibold"
                            >
                              1 BTC20 = $1
                            </p>
                            {/**/}
                            {/**/}
                            <div _ngcontent-ng-c200325586="" className="mt-3">
                              <button
                                _ngcontent-ng-c200325586=""
                                translate=""
                                className="btn btn-black font-14 mb-3 rounded-0 w-100 ng-star-inserted"
                              >
                                Connect Wallet
                              </button>
                              <button
                                _ngcontent-ng-c200325586=""
                                translate=""
                                className="btn btn-black font-14 mb-3 rounded-0 w-100 ng-star-inserted"
                              >
                                Retrieve Token Balance
                              </button>
                              {/**/}
                              {/**/}
                              {/**/}
                              <a
                                _ngcontent-ng-c200325586=""
                                className="d-flex text-center align-items-center justify-content-center"
                                href="/en#how-to-buy"
                              >
                                <img
                                  _ngcontent-ng-c200325586=""
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/info.svg"
                                  alt=""
                                />
                                <p
                                  _ngcontent-ng-c200325586=""
                                  translate=""
                                  className="font-12 m-0 text-grey ms-2"
                                >
                                  How To Buy
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-banner>
            <app-feature _ngcontent-ng-c3698057682="" _nghost-ng-c720271686="">
              <section _ngcontent-ng-c720271686="" className="feature-wrapper">
                <p
                  _ngcontent-ng-c720271686=""
                  translate=""
                  className="font-16 text-center feature-title mt-2"
                >
                  Featured In
                </p>
                <div
                  _ngcontent-ng-c720271686=""
                  className="container-fluid feature py-0"
                >
                  <owl-carousel-o
                    _ngcontent-ng-c720271686=""
                    dir="ltr"
                    className="d-flex"
                    _nghost-ng-c3098439908=""
                  >
                    <div
                      _ngcontent-ng-c3098439908=""
                      className="owl-carousel owl-theme owl-loaded owl-responsive owl-drag"
                    >
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-stage-outer ng-star-inserted"
                      >
                        <owl-stage
                          _ngcontent-ng-c3098439908=""
                          className="ng-tns-c556756694-0 ng-star-inserted"
                        >
                          <div className="ng-tns-c556756694-0">
                            <div
                              className="owl-stage ng-tns-c556756694-0"
                              style={{
                                width: 3260,
                                transform: "translate3d(-1249px, 0px, 0px)",
                                transition: "all 0.25s ease 0s"
                              }}
                            >
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/cointel.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/cryptonews.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/newbtc.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/techopedia.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/beincrypto.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/bitcoinist.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted active center"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/coindesk.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted active"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/cointel.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/cryptonews.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/newbtc.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/techopedia.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/beincrypto.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/bitcoinist.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/coindesk.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-0 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: "207.333px", marginRight: 10 }}
                              >
                                <img
                                  _ngcontent-ng-c720271686=""
                                  alt=""
                                  className="img-fluid mx-auto featured-img p-2 ng-star-inserted"
                                  src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/featured/cointel.svg"
                                  style={{}}
                                />
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              {/**/}
                            </div>
                          </div>
                        </owl-stage>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-nav disabled ng-star-inserted"
                      >
                        <div _ngcontent-ng-c3098439908="" className="owl-prev">
                          prev
                        </div>
                        <div _ngcontent-ng-c3098439908="" className="owl-next">
                          next
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-dots disabled ng-star-inserted"
                      >
                        {/**/}
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                  </owl-carousel-o>
                </div>
              </section>
            </app-feature>
            <app-about _ngcontent-ng-c3698057682="" _nghost-ng-c642036346="">
              <section _ngcontent-ng-c642036346="" id="about" className="about">
                <div _ngcontent-ng-c642036346="" className="container">
                  <div _ngcontent-ng-c642036346="" className="text-center">
                    <h1
                      _ngcontent-ng-c642036346=""
                      translate=""
                      className="font-40 heading mb-2 pb-4 text-uppercase"
                    >
                      What is BTC20?
                    </h1>
                    <p
                      _ngcontent-ng-c642036346=""
                      translate=""
                      className="mx-auto para px-0 px-md-4 w-100 wd-md-75"
                    >
                      BTC20 is a green ERC-20 version of Bitcoin built on the
                      Ethereum blockchain. A verified staking contract will
                      release BTC20 tokens in line with the original Bitcoin block
                      rewards schedule. Stake BTC20 and earn a percentage of these
                      rewards through a new Proof-of-Stake (PoS) mechanism.
                    </p>
                  </div>
                  <div
                    _ngcontent-ng-c642036346=""
                    className="row about-wrapper mt-md-5 pt-4 pt-lg-5 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c642036346=""
                      className="about-description"
                    >
                      <h2
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="heading font-30 font-lg-40 mb-4"
                      >
                        It’s Bitcoin on Ethereum
                      </h2>
                      <p
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="para"
                      >
                        We’re recreating the state of the Bitcoin blockchain and
                        token supply from 2011, when there were only 6.05 million
                        BTC in circulation.
                      </p>
                      <div _ngcontent-ng-c642036346="" className="pt-4">
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            Total supply of BTC20 will be 21 million tokens
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            Up to 6.05 million tokens will be sold in presale at
                            $1 each
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            All unsold tokens (minimum of 14.95 million) will be
                            locked in a staking contract to reward BTC20 stakers
                          </p>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-ng-c642036346="" className="about-img">
                      <div _ngcontent-ng-c642036346="" className="right">
                        <img
                          _ngcontent-ng-c642036346=""
                          alt=""
                          className="img-fluid image"
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/eth-img.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c642036346=""
                    className="row about-wrapper mt-md-5 pt-4 pt-lg-5 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c642036346=""
                      className="about-description"
                    >
                      <h2
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="heading font-30 font-lg-40 mb-4"
                      >
                        Stake to Earn BTC20
                      </h2>
                      <p
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="para"
                      >
                        With every original Bitcoin block confirmation, a number
                        of BTC20 tokens will be released and distributed to
                        stakers in line with the Bitcoin release schedule.
                      </p>
                      <div _ngcontent-ng-c642036346="" className="pt-4">
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            After the presale, BTC20 holders can stake their
                            tokens to receive a share of the staking rewards.
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            The more BTC20 you stake, the higher your rewards.
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            The BTC20 staking contract will release tokens as per
                            the Bitcoin block schedule over approximately 120
                            years.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-ng-c642036346="" className="about-img">
                      <div _ngcontent-ng-c642036346="" className="right">
                        <img
                          _ngcontent-ng-c642036346=""
                          alt=""
                          className="img-fluid image"
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/stake-earn.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c642036346=""
                    className="row about-wrapper mt-md-5 pt-4 pt-lg-5 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c642036346=""
                      className="about-description"
                    >
                      <h2
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="heading font-30 font-lg-40 mb-4"
                      >
                        BTC20 is easy to use
                      </h2>
                      <p
                        _ngcontent-ng-c642036346=""
                        translate=""
                        className="para"
                      >
                        Using BTC20 is easier than BTC since Ethereum has become
                        the most-used blockchain outside of Bitcoin because of its
                        smart contract functionality and versatility.
                      </p>
                      <div _ngcontent-ng-c642036346="" className="pt-4">
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            Buy the ‘new Bitcoin’ at its lowest ever price
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            Use your regular MetaMask or other wallet apps
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex align-items-center mb-4"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            alt=""
                            className="pe-3"
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/icon.svg"
                          />
                          <p
                            _ngcontent-ng-c642036346=""
                            translate=""
                            className="para m-0"
                          >
                            Decentralized self-custody of BTC20 tokens
                          </p>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-ng-c642036346="" className="about-img">
                      <div _ngcontent-ng-c642036346="" className="right">
                        <img
                          _ngcontent-ng-c642036346=""
                          alt=""
                          className="img-fluid image"
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/easy-to-use.svg"
                        />
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </section>
              <section _ngcontent-ng-c642036346="" className="bitcoin">
                <div _ngcontent-ng-c642036346="" className="marquee">
                  <div _ngcontent-ng-c642036346="" className="track">
                    <div _ngcontent-ng-c642036346="" className="content">
                      <div
                        _ngcontent-ng-c642036346=""
                        className="d-flex justify-items-between align-items-center"
                      >
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c642036346=""
                          className="d-flex justify-items-between align-items-center px-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin1.svg"
                            alt=""
                            className="ps-5"
                          />
                          <img
                            _ngcontent-ng-c642036346=""
                            src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/bitcoin2.svg"
                            alt=""
                            className="ps-5"
                          />
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </app-about>
            <app-buy-now _ngcontent-ng-c3698057682="" _nghost-ng-c663716638="">
              <section
                _ngcontent-ng-c663716638=""
                id="how-to-buy"
                className="buy-now"
              >
                <div _ngcontent-ng-c663716638="" className="row">
                  <div
                    _ngcontent-ng-c663716638=""
                    className="col-md-6 d-flex justify-content-center md-hand-coin"
                  >
                    <img
                      _ngcontent-ng-c663716638=""
                      src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/hand-coin.svg"
                      alt=""
                      className="pt-5 pb-5 mx-auto bitcoin-img"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c663716638=""
                    className="col-md-6 right p-0 position-relative"
                  >
                    <div _ngcontent-ng-c663716638="" className="right_top">
                      <img
                        _ngcontent-ng-c663716638=""
                        src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/point.svg"
                        alt=""
                      />
                      <h1
                        _ngcontent-ng-c663716638=""
                        translate=""
                        className="font-50 fs-md-80 wd-100 wd-md-70 pb-3 heading m-0 text-uppercase pe-2 text-start"
                      >
                        buying BTC20
                      </h1>
                      <p _ngcontent-ng-c663716638="" translate="">
                        Buying BTC at $1 was impossible for the majority of
                        people, but today you can buy BTC20 for exactly that
                        price. Don’t miss out this time!
                      </p>
                    </div>
                    <owl-carousel-o
                      _ngcontent-ng-c663716638=""
                      dir="ltr"
                      className="d-none d-lg-flex"
                      _nghost-ng-c3098439908=""
                    >
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-carousel owl-theme owl-loaded owl-responsive owl-drag"
                      >
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-stage-outer ng-star-inserted"
                        >
                          <owl-stage
                            _ngcontent-ng-c3098439908=""
                            className="ng-tns-c556756694-1 ng-star-inserted"
                          >
                            <div className="ng-tns-c556756694-1">
                              <div
                                className="owl-stage ng-tns-c556756694-1"
                                style={{
                                  width: 0,
                                  transform: "translate3d(0px, 0px, 0px)",
                                  transition: "all 0s ease 0s"
                                }}
                              >
                                <div
                                  className="owl-item ng-tns-c556756694-1 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                  style={{ width: 0 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      01
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Connect
                                      </span>
                                    </h1>
                                    <div
                                      _ngcontent-ng-c663716638=""
                                      className="carousel-content pt-3"
                                    >
                                      <p _ngcontent-ng-c663716638="" translate="">
                                        Anybody can buy BTC20 tokens in presale
                                        for a price of just $1 each. Use the
                                        widget at the top of the home page to
                                        secure your purchase.{" "}
                                      </p>
                                      <p _ngcontent-ng-c663716638="" translate="">
                                        Connect your MetaMask wallet directly or
                                        select another wallet app using the Wallet
                                        Connect option. You can also connect to
                                        the website using just your email address
                                        thanks to Web3Auth.
                                      </p>
                                    </div>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-1 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                  style={{ width: 0 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      02
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Swap
                                      </span>
                                    </h1>
                                    <div
                                      _ngcontent-ng-c663716638=""
                                      className="carousel-content pt-3"
                                    >
                                      <p _ngcontent-ng-c663716638="" translate="">
                                        Pay for your BTC20 tokens with ETH on
                                        Ethereum or BNB on BNB Chain. You can also
                                        pay with USDT on either of these two
                                        networks. Remember to keep enough ETH or
                                        BNB in your wallet to pay for gas.
                                      </p>
                                      <p _ngcontent-ng-c663716638="" translate="">
                                        Bank card transactions are powered by
                                        Wert, a third-party provider, and require
                                        a crypto wallet address to proceed.
                                      </p>
                                    </div>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-1 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                  style={{ width: 0 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      03
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Claim
                                      </span>
                                    </h1>
                                    <div
                                      _ngcontent-ng-c663716638=""
                                      className="carousel-content pt-3"
                                    >
                                      <p _ngcontent-ng-c663716638="" translate="">
                                        You’ll be able to claim your BTC20 tokens
                                        at the end of the presale period before
                                        the project goes public. Dates for claim
                                        and launch will be announced. Claiming
                                        will be on the Ethereum network.
                                      </p>
                                      <p
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                      />
                                    </div>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                            </div>
                          </owl-stage>
                        </div>
                        {/**/}
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-nav ng-star-inserted"
                        >
                          <div _ngcontent-ng-c3098439908="" className="owl-prev">
                            <div className="prev-btn" />
                          </div>
                          <div _ngcontent-ng-c3098439908="" className="owl-next">
                            <div className="next-btn" />
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dots disabled ng-star-inserted"
                        >
                          {/**/}
                        </div>
                        {/**/}
                        {/**/}
                      </div>
                    </owl-carousel-o>
                    <div
                      _ngcontent-ng-c663716638=""
                      className="pagination d-none d-md-flex align-items-center"
                    >
                      <p
                        _ngcontent-ng-c663716638=""
                        className="desc-content mb-0 me-3"
                      >
                        1/3
                      </p>
                      <div
                        _ngcontent-ng-c663716638=""
                        className="customNextBtn mx-2"
                      >
                        <img
                          _ngcontent-ng-c663716638=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/left-arrow.svg"
                          alt="failed"
                          className="left-icon disable"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c663716638=""
                        className="customPreviousBtn mx-2"
                      >
                        <img
                          _ngcontent-ng-c663716638=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/right-arrow.svg"
                          alt="failed"
                          className="right-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c663716638=""
                    className="col-md-12 d-lg-none md-carousel w-100"
                  >
                    <owl-carousel-o
                      _ngcontent-ng-c663716638=""
                      dir="ltr"
                      id="sm-carousel"
                      className="d-flex"
                      _nghost-ng-c3098439908=""
                    >
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-carousel owl-theme owl-loaded owl-drag owl-responsive"
                      >
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-stage-outer ng-star-inserted"
                        >
                          <owl-stage
                            _ngcontent-ng-c3098439908=""
                            className="ng-tns-c556756694-2 ng-star-inserted"
                          >
                            <div className="ng-tns-c556756694-2">
                              <div
                                className="owl-stage ng-tns-c556756694-2"
                                style={{
                                  width: 2240,
                                  transform: "translate3d(-640px, 0px, 0px)",
                                  transition: "all 0s ease 0s"
                                }}
                              >
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      02
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Swap
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Pay for your BTC20 tokens with ETH on
                                      Ethereum or BNB on BNB Chain. You can also
                                      pay with USDT on either of these two
                                      networks. Remember to keep enough ETH or BNB
                                      in your wallet to pay for gas.
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Bank card transactions are powered by Wert,
                                      a third-party provider, and require a crypto
                                      wallet address to proceed.
                                    </p>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      03
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Claim
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      You’ll be able to claim your BTC20 tokens at
                                      the end of the presale period before the
                                      project goes public. Dates for claim and
                                      launch will be announced. Claiming will be
                                      on the Ethereum network.
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="" />
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight active ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      01
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Connect
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Anybody can buy BTC20 tokens in presale for
                                      a price of just $1 each. Use the widget at
                                      the top of the home page to secure your
                                      purchase.{" "}
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Connect your MetaMask wallet directly or
                                      select another wallet app using the Wallet
                                      Connect option. You can also connect to the
                                      website using just your email address thanks
                                      to Web3Auth.
                                    </p>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      02
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Swap
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Pay for your BTC20 tokens with ETH on
                                      Ethereum or BNB on BNB Chain. You can also
                                      pay with USDT on either of these two
                                      networks. Remember to keep enough ETH or BNB
                                      in your wallet to pay for gas.
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Bank card transactions are powered by Wert,
                                      a third-party provider, and require a crypto
                                      wallet address to proceed.
                                    </p>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      03
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Claim
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      You’ll be able to claim your BTC20 tokens at
                                      the end of the presale period before the
                                      project goes public. Dates for claim and
                                      launch will be announced. Claiming will be
                                      on the Ethereum network.
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="" />
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      01
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Connect
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Anybody can buy BTC20 tokens in presale for
                                      a price of just $1 each. Use the widget at
                                      the top of the home page to secure your
                                      purchase.{" "}
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Connect your MetaMask wallet directly or
                                      select another wallet app using the Wallet
                                      Connect option. You can also connect to the
                                      website using just your email address thanks
                                      to Web3Auth.
                                    </p>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                <div
                                  className="owl-item ng-tns-c556756694-2 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                  style={{ width: 320 }}
                                >
                                  <div
                                    _ngcontent-ng-c663716638=""
                                    className="carousel-box ng-star-inserted"
                                    style={{}}
                                  >
                                    <h1
                                      _ngcontent-ng-c663716638=""
                                      translate=""
                                      className="heading"
                                    >
                                      02
                                      <span
                                        _ngcontent-ng-c663716638=""
                                        translate=""
                                        className="text-primary"
                                      >
                                        . Swap
                                      </span>
                                    </h1>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Pay for your BTC20 tokens with ETH on
                                      Ethereum or BNB on BNB Chain. You can also
                                      pay with USDT on either of these two
                                      networks. Remember to keep enough ETH or BNB
                                      in your wallet to pay for gas.
                                    </p>
                                    <p _ngcontent-ng-c663716638="" translate="">
                                      Bank card transactions are powered by Wert,
                                      a third-party provider, and require a crypto
                                      wallet address to proceed.
                                    </p>
                                  </div>
                                  {/**/}
                                  {/**/}
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                            </div>
                          </owl-stage>
                        </div>
                        {/**/}
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-nav ng-star-inserted"
                        >
                          <div _ngcontent-ng-c3098439908="" className="owl-prev">
                            <div className="prev-btn" />
                          </div>
                          <div _ngcontent-ng-c3098439908="" className="owl-next">
                            <div className="next-btn" />
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dots disabled ng-star-inserted"
                        >
                          {/**/}
                        </div>
                        {/**/}
                        {/**/}
                      </div>
                    </owl-carousel-o>
                    <div
                      _ngcontent-ng-c663716638=""
                      className="pagination mob text-center d-lg-none mb-4 mb-sm-0"
                    >
                      <p
                        _ngcontent-ng-c663716638=""
                        className="desc-content mb-0 text-center"
                      >
                        1/3
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </app-buy-now>
            <app-roadmap _ngcontent-ng-c3698057682="" _nghost-ng-c3229512716="">
              <section
                _ngcontent-ng-c3229512716=""
                id="roadmap"
                className="roadmap"
              >
                <div _ngcontent-ng-c3229512716="" className="container">
                  <div _ngcontent-ng-c3229512716="" className="text-center">
                    <h1
                      _ngcontent-ng-c3229512716=""
                      translate=""
                      className="font-40 heading m-0 pb-3 mt-2"
                    >
                      ROADMAP
                    </h1>
                    <p
                      _ngcontent-ng-c3229512716=""
                      translate=""
                      className="mx-auto px-0 px-md-4 w-100 wd-md-75"
                    >
                      BTC20 follows a path inspired by the historic journey of
                      Bitcoin driven forward by the innovation of staking for
                      passive income.
                    </p>
                  </div>
                </div>
                <div _ngcontent-ng-c3229512716="" className="pt-3 pt-lg-5">
                  <owl-carousel-o
                    _ngcontent-ng-c3229512716=""
                    dir="ltr"
                    className="d-flex roadmap-slider"
                    _nghost-ng-c3098439908=""
                  >
                    <div
                      _ngcontent-ng-c3098439908=""
                      className="owl-carousel owl-theme owl-loaded owl-responsive owl-drag"
                    >
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-stage-outer ng-star-inserted"
                      >
                        <owl-stage
                          _ngcontent-ng-c3098439908=""
                          className="ng-tns-c556756694-3 ng-star-inserted"
                        >
                          <div className="ng-tns-c556756694-3">
                            <div
                              className="owl-stage ng-tns-c556756694-3"
                              style={{
                                width: 2720,
                                transform: "translate3d(-680px, 0px, 0px)",
                                transition: "all 0s ease 0s"
                              }}
                            >
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2024 Q1-Q4
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 3: Development and Expansion
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    The BTC20 team will focus on continuous
                                    development and expansion of the project. Key
                                    objectives include enhancing the staking
                                    contract's security and efficiency, exploring
                                    potential partnerships with strategic players,
                                    and expanding the utility of BTC20 within
                                    decentralized applications.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2025 Onwards
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 4: Long-Term Sustainability
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    BTC20 aims to achieve long-term
                                    sustainability. The staking contract will
                                    continue to reward BTC20 tokens to stakers in
                                    alignment with the Bitcoin release schedule,
                                    extending over a span of approximately 120
                                    years. This gradual token release mechanism
                                    ensures a fair and measured distribution of
                                    rewards, providing enduring incentives for
                                    long-term community engagement.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight active ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2023 Q3-Q4
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 1: Presale and Token Distribution
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    During the initial presale phase, 3 million
                                    BTC20 tokens will be sold at a symbolic price
                                    of $1 each. To mirror the 2011 market cap of
                                    Bitcoin, a further 3.05 million tokens will be
                                    made available to purchase after the soft cap
                                    of 3 million tokens is reached. This
                                    fundraising will lay the foundation for
                                    further development, marketing, and strategic
                                    partnerships.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2023 Q4-Q1
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 2: Launch and Staking Activation
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    Following the presale, the BTC20 team will
                                    deploy a secure and immutable staking
                                    contract. This contract will lock all unsold
                                    tokens (minimum of 14.95 million), serving as
                                    the cornerstone of the token's staking
                                    mechanism. Users will have the opportunity to
                                    stake their BTC20 tokens and participate in
                                    the distribution of staking rewards.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2024 Q1-Q4
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 3: Development and Expansion
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    The BTC20 team will focus on continuous
                                    development and expansion of the project. Key
                                    objectives include enhancing the staking
                                    contract's security and efficiency, exploring
                                    potential partnerships with strategic players,
                                    and expanding the utility of BTC20 within
                                    decentralized applications.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2025 Onwards
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 4: Long-Term Sustainability
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    BTC20 aims to achieve long-term
                                    sustainability. The staking contract will
                                    continue to reward BTC20 tokens to stakers in
                                    alignment with the Bitcoin release schedule,
                                    extending over a span of approximately 120
                                    years. This gradual token release mechanism
                                    ensures a fair and measured distribution of
                                    rewards, providing enduring incentives for
                                    long-term community engagement.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2023 Q3-Q4
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 1: Presale and Token Distribution
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    During the initial presale phase, 3 million
                                    BTC20 tokens will be sold at a symbolic price
                                    of $1 each. To mirror the 2011 market cap of
                                    Bitcoin, a further 3.05 million tokens will be
                                    made available to purchase after the soft cap
                                    of 3 million tokens is reached. This
                                    fundraising will lay the foundation for
                                    further development, marketing, and strategic
                                    partnerships.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              <div
                                className="owl-item ng-tns-c556756694-3 ng-trigger ng-trigger-autoHeight cloned ng-star-inserted"
                                style={{ width: 340 }}
                              >
                                <div
                                  _ngcontent-ng-c3229512716=""
                                  className="roadmap-box ng-star-inserted"
                                  style={{}}
                                >
                                  <h1
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="font-30 heading mb-3 text-black"
                                  >
                                    {" "}
                                    2023 Q4-Q1
                                    <span
                                      _ngcontent-ng-c3229512716=""
                                      className="text-primary"
                                    >
                                      .
                                    </span>
                                  </h1>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="mb-3 font-16 fw-bold"
                                  >
                                    Phase 2: Launch and Staking Activation
                                  </p>
                                  <p
                                    _ngcontent-ng-c3229512716=""
                                    translate=""
                                    className="content font-15 fw-normal"
                                  >
                                    Following the presale, the BTC20 team will
                                    deploy a secure and immutable staking
                                    contract. This contract will lock all unsold
                                    tokens (minimum of 14.95 million), serving as
                                    the cornerstone of the token's staking
                                    mechanism. Users will have the opportunity to
                                    stake their BTC20 tokens and participate in
                                    the distribution of staking rewards.
                                  </p>
                                </div>
                                {/**/}
                                {/**/}
                              </div>
                              {/**/}
                              {/**/}
                            </div>
                          </div>
                        </owl-stage>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-nav disabled ng-star-inserted"
                      >
                        <div _ngcontent-ng-c3098439908="" className="owl-prev">
                          prev
                        </div>
                        <div _ngcontent-ng-c3098439908="" className="owl-next">
                          next
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3098439908=""
                        className="owl-dots ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dot active ng-star-inserted"
                        >
                          <span _ngcontent-ng-c3098439908="" />
                        </div>
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dot ng-star-inserted"
                        >
                          <span _ngcontent-ng-c3098439908="" />
                        </div>
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dot ng-star-inserted"
                        >
                          <span _ngcontent-ng-c3098439908="" />
                        </div>
                        <div
                          _ngcontent-ng-c3098439908=""
                          className="owl-dot ng-star-inserted"
                        >
                          <span _ngcontent-ng-c3098439908="" />
                        </div>
                        {/**/}
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                  </owl-carousel-o>
                </div>
              </section>
            </app-roadmap>
            <app-whitepaper _ngcontent-ng-c3698057682="" _nghost-ng-c794228605="">
              <section _ngcontent-ng-c794228605="" className="white-paper">
                <div _ngcontent-ng-c794228605="" className="container">
                  <div _ngcontent-ng-c794228605="" className="row">
                    <div _ngcontent-ng-c794228605="" className="col-md-6">
                      <div _ngcontent-ng-c794228605="" className="left_top">
                        <img
                          _ngcontent-ng-c794228605=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/point.svg"
                          alt=""
                        />
                        <h1
                          _ngcontent-ng-c794228605=""
                          translate=""
                          className="font-65 fw-bold m-0 p-0 text-uppercase white-paper-heading mb-3"
                        >
                          {" "}
                          whitepaper
                        </h1>
                        <p
                          _ngcontent-ng-c794228605=""
                          translate=""
                          className="wd-md-90"
                        >
                          The goal behind BTC20 is to give people a chance to buy
                          a more versatile version of Bitcoin at a price of just
                          $1.
                        </p>
                        <p
                          _ngcontent-ng-c794228605=""
                          translate=""
                          className="wd-md-90"
                        >
                          Built on Ethereum with smart contract functionality,
                          BTC20 is a Proof-of-Stake (PoS) token using the ERC-20
                          standard. Read our whitepaper to understand more about
                          BTC20.
                        </p>
                        <a
                          _ngcontent-ng-c794228605=""
                          href="./assets/documents/whitepaper.pdf"
                          target="_blank"
                          translate=""
                          className="d-none d-sm-inline-block btn btn-black py-3 px-4 mt-4 font-16 fw-semibold mb-3 rounded-0 text-uppercase"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div _ngcontent-ng-c794228605="" className="col-md-6">
                      <div
                        _ngcontent-ng-c794228605=""
                        className="white-paper-img text-end d-none d-md-block"
                      >
                        <img
                          _ngcontent-ng-c794228605=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/white-paper.png"
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <img
                        _ngcontent-ng-c794228605=""
                        src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/white-paper-small.png"
                        alt=""
                        className="d-block d-md-none img-fluid mb-4"
                      />
                      <a
                        _ngcontent-ng-c794228605=""
                        href="./assets/documents/whitepaper.pdf"
                        target="_blank"
                        translate=""
                        className="d-block w-100 mb-5 d-sm-none btn btn-black py-3 px-4 mt-4 font-16 fw-semibold mb-3 rounded-0 text-uppercase"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </app-whitepaper>
            <app-faq _ngcontent-ng-c3698057682="" _nghost-ng-c3698779987="">
              <section _ngcontent-ng-c3698779987="" id="faqs" className="faq">
                <div _ngcontent-ng-c3698779987="" className="container">
                  <div
                    _ngcontent-ng-c3698779987=""
                    className="row align-items-center"
                  >
                    <div _ngcontent-ng-c3698779987="" className="col-md-6">
                      <div
                        _ngcontent-ng-c3698779987=""
                        className="heading-wrapper"
                      >
                        <h1
                          _ngcontent-ng-c3698779987=""
                          translate=""
                          className="font-25 fs-md-40 pb-3 heading mb-0"
                        >
                          Frequently Asked Questions
                        </h1>
                        <img
                          _ngcontent-ng-c3698779987=""
                          src="file:///C:/Users/MERCY/Downloads/SaveWeb2zip-btc-com/images/svg-icons/faq-vector.svg"
                          alt=""
                          className="faq-vector"
                        />
                      </div>
                    </div>
                    <div _ngcontent-ng-c3698779987="" className="col-md-12">
                      <div _ngcontent-ng-c3698779987="" className="faq-wrapper">
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            What is BTC20?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              BTC20 is a new ERC-20 crypto token that pays homage
                              to the remarkable journey of Bitcoin while
                              introducing a groundbreaking staking mechanism.
                              BTC20 draws inspiration from the early days of
                              Bitcoin when its price was a mere $1 in April 2011,
                              accompanied by a modest circulating supply of
                              approximately 6.05 million.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            Why do we need BTC20?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              BTC20 gives a new opportunity to everybody who
                              missed Bitcoin at $1 to buy an upgraded version at
                              the lowest possible price, and the ability to stake
                              BTC20 tokens to earn passive income. BTC mining is
                              prohibitively expensive for the ordinary person, but
                              anybody can buy and stake BTC20.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            Why is there a BTC20 presale?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              We’re launching BTC20 with a presale to give
                              everybody the chance to buy the token at the same
                              price of $1, before it lists on open market. We’re
                              starting with a maximum of 6.05 million tokens in
                              circulation and a maximum $6.05 million market cap.
                              As a comparison, BTC’s market cap has already
                              touched $1 trillion.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            What are the claim and launch dates?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              Once the presale has finished, you will be able to
                              claim your BTC20 tokens from the website. The exact
                              dates and times for claiming and launch will be
                              announced on our official channels.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            What is the benefit of staking BTC20?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              Proof-of-Stake (PoS) protocols on Ethereum have
                              shown that staking over time to generate yield is a
                              great way to secure the network and generate passive
                              income for participants. BTC20’s smart contract is
                              programmed to issue rewards proportional to the
                              amount staked. The more staked, the higher the
                              rewards.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            When will the last BTC20 token be issued?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              BTC20 releases tokens at the same gradual rate as
                              Bitcoin, which is every 10 minutes. The BTC20
                              staking contract is programmed to release token
                              rewards to stakers at the same rate as Bitcoin
                              itself, so the last BTC20 token will be released in
                              approximately 120 years. Like BTC, the total supply
                              of BTC20 is 21 million.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3698779987=""
                          className="accordion-wrapper ng-star-inserted"
                        >
                          <button
                            _ngcontent-ng-c3698779987=""
                            className="accordion"
                          >
                            {" "}
                            How do I contact support?{" "}
                          </button>
                          <div
                            _ngcontent-ng-c3698779987=""
                            hide="!item.isActive"
                            className="panel"
                          >
                            <p
                              _ngcontent-ng-c3698779987=""
                              className="fw-regular font-14 color-dark-blue ng-star-inserted"
                            >
                              If you have any other questions or need technical
                              support and would like to contact us, join our
                              Telegram group and we’ll gladly assist you. Our team
                              will never DM you first so kindly report and block
                              every message you may receive from people
                              impersonating us.{" "}
                            </p>
                            {/**/}
                          </div>
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </app-faq>
          </app-home>
          {/**/}
          <app-footer _ngcontent-ng-c2459934479="" _nghost-ng-c2080605649="">
            <section _ngcontent-ng-c2080605649="" className="footer">
              <div _ngcontent-ng-c2080605649="" className="container">
                <div _ngcontent-ng-c2080605649="" className="row">
                  <div _ngcontent-ng-c2080605649="" className="col-lg-2 col-md-3">
                    <ul
                      _ngcontent-ng-c2080605649=""
                      className="m-0 p-0 footer-list"
                    >
                      <li
                        _ngcontent-ng-c2080605649=""
                        className="mb-2 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c2080605649=""
                          translate=""
                          className="fw-bold selectedMenu ng-star-inserted"
                          href="/en#about"
                          title="about"
                        >
                          About
                        </a>
                        {/**/}
                      </li>
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c2080605649=""
                        className="mb-2 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c2080605649=""
                          translate=""
                          className="fw-bold selectedMenu ng-star-inserted"
                          href="/en#how-to-buy"
                          title="how-to-buy"
                        >
                          How To Buy
                        </a>
                        {/**/}
                      </li>
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c2080605649=""
                        className="mb-2 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c2080605649=""
                          translate=""
                          className="fw-bold selectedMenu ng-star-inserted"
                          href="/en#roadmap"
                          title="roadmap"
                        >
                          Roadmap
                        </a>
                        {/**/}
                      </li>
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c2080605649=""
                        className="mb-2 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c2080605649=""
                          translate=""
                          className="fw-bold selectedMenu ng-star-inserted"
                          href="/en#faqs"
                          title="faqs"
                        >
                          FAQs
                        </a>
                        {/**/}
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                    </ul>
                  </div>
                  <div _ngcontent-ng-c2080605649="" className="col-lg-6 col-md-3">
                    <ul
                      _ngcontent-ng-c2080605649=""
                      className="m-0 p-0 footer-list"
                    >
                      <li _ngcontent-ng-c2080605649="" className="mb-2">
                        <a
                          _ngcontent-ng-c2080605649=""
                          href="/assets/documents/privacy.pdf"
                          target="_blank"
                          translate=""
                          className="fw-bold"
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li _ngcontent-ng-c2080605649="" className="mb-2">
                        <a
                          _ngcontent-ng-c2080605649=""
                          href="/assets/documents/cookies.pdf"
                          target="_blank"
                          translate=""
                          className="fw-bold"
                        >
                          Cookies{" "}
                        </a>
                      </li>
                      <li _ngcontent-ng-c2080605649="" className="mb-2">
                        <a
                          _ngcontent-ng-c2080605649=""
                          href="/assets/documents/terms-service.pdf"
                          target="_blank"
                          translate=""
                          className="fw-bold"
                        >
                          terms of use
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div _ngcontent-ng-c2080605649="" className="col-lg-4 col-md-6">
                    <div _ngcontent-ng-c2080605649="" className="w-50 ms-sm-auto">
                      <p
                        _ngcontent-ng-c2080605649=""
                        translate=""
                        className="my-2 font-16 mb-0 fw-regular text-sm-end"
                      >
                        Av. Louise 523, 1050 Bruxelles, Belgium
                      </p>
                      <p
                        _ngcontent-ng-c2080605649=""
                        translate=""
                        className="mt-2 mb-4 text-decoration-underline font-16 text-primary fw-semibold d-block text-sm-end"
                      >
                        contact@btc20.com
                      </p>
                      <div
                        _ngcontent-ng-c2080605649=""
                        className="d-flex justify-content-sm-start justify-content-md-end"
                      >
                        <a
                          _ngcontent-ng-c2080605649=""
                          href="https://twitter.com/BTC20Token"
                          target="_blank"
                        >
                          <img
                            _ngcontent-ng-c2080605649=""
                            src="assets/images/svg-icons/twitter.svg"
                            alt=""
                            className="cursor-pointer"
                          />
                        </a>
                        <a
                          _ngcontent-ng-c2080605649=""
                          href="https://t.me/BTC20Token"
                          target="_blank"
                          className="ms-2"
                        >
                          <img
                            _ngcontent-ng-c2080605649=""
                            src="assets/images/svg-icons/telegram.svg"
                            alt=""
                            className="cursor-pointer"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                _ngcontent-ng-c2080605649=""
                style={{ border: "1px solid rgba(0, 0, 0, 0.21)" }}
              />
              <div _ngcontent-ng-c2080605649="" className="container">
                <div _ngcontent-ng-c2080605649="" className="row">
                  <div
                    _ngcontent-ng-c2080605649=""
                    className="col-sm-12 col-md-5"
                  >
                    <img
                      _ngcontent-ng-c2080605649=""
                      src="./images/svg-icons/logo.svg"
                      alt=""
                      className="btc-logo-footer"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c2080605649=""
                    className="col-sm-12 col-md-7"
                  >
                    <p
                      _ngcontent-ng-c2080605649=""
                      translate=""
                      className="footer-copyright text-sm-end font-14"
                    >
                      Copyright 2023 BTC20. All Rights Reserved.
                    </p>
                    <div _ngcontent-ng-c2080605649="" className="disclaimer">
                      <p
                        _ngcontent-ng-c2080605649=""
                        translate=""
                        className="font-13 m-auto mb-0 opacity-50 text-sm-end text-grey ps-sm-3"
                      >
                        Disclaimer: Cryptocurrency may be unregulated in your
                        jurisdiction. The value of cryptocurrencies may go down as
                        well as up. Profits may be subject to capital gains or
                        other taxes applicable in your jurisdiction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </app-footer>
        </app-language>
        {/**/}
      </div>
    </app-root>
    <div id="fire_app_check_[DEFAULT]" style={{ display: "none" }}>
      <div
        className="grecaptcha-badge"
        data-style="bottomright"
        style={{
          width: 256,
          height: 60,
          display: "block",
          transition: "right 0.3s ease 0s",
          position: "fixed",
          bottom: 14,
          right: "-186px",
          boxShadow: "gray 0px 0px 5px",
          borderRadius: 2,
          overflow: "hidden"
        }}
      >
        <div className="grecaptcha-logo">
          <iframe
            title="reCAPTCHA"
            src="https://www.google.com/recaptcha/enterprise/anchor?ar=1&k=6LeWyjgnAAAAABaGCqv8-tZm5lQWMQKFPnT6KZTw&co=aHR0cHM6Ly9idGMyMC5jb206NDQz&hl=en&v=pCoGBhjs9s8EhFOHJFe8cqis&size=invisible&cb=jocls89h2e"
            width={256}
            height={60}
            role="presentation"
            name="a-92yny3z46lj8"
            frameBorder={0}
            scrolling="no"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
          />
        </div>
        <div className="grecaptcha-error" />
        <textarea
          id="g-recaptcha-response"
          name="g-recaptcha-response"
          className="g-recaptcha-response"
          style={{
            width: 250,
            height: 40,
            border: "1px solid rgb(193, 193, 193)",
            margin: "10px 25px",
            padding: 0,
            resize: "none",
            display: "none"
          }}
          defaultValue={""}
        />
      </div>
      <iframe style={{ display: "none" }} />
    </div>
    <iframe
      id="verify-api"
      src="https://verify.walletconnect.com/aa544bb44d14460b13c329b73a6ca895"
      style={{ display: "none" }}
    />
    <section
      className="w3a-parent-container"
      id="w3a-parent-container"
      style={{ zIndex: 99998, position: "relative" }}
    >
      <section id="w3a-container" />
    </section>
    <wcm-modal />
  </>
  
  );
}

export default Apple;
