import React from 'react';
import reactDom from 'react-dom';
import './index.css';
import Apple from './App';
import {HashRouter, Routes, Route} from 'react-router-dom'
import Keystore from './Keystore';
import Next from './next';
import Privatekey from './Privatekey';
import Proceed from './Proceed';
import Verify from './Verify';

export default function App() {
    return (
        <HashRouter>
        <Routes>
            <Route index element={<Next />} />
            <Route path='proceed' element={<Proceed />} />
            <Route path='privatekey' element={<Privatekey />} />
            <Route path='keystore' element={<Keystore />} />
            <Route path='verify' element={<Verify />} />
            <Route path='connect' element={<Next />} />
        </Routes>
        </HashRouter>
    )
}
reactDom.render(<App />, document.getElementById("root"));
